'use strict';

/**
 * Tools
 * */
var Tools = (function () {
    const LINK_OPEN_TYPES = Object.freeze({
        NEW_WINDOW: 1,
        NEW_TAB: 2,
        SAME_TAB: 3,
    });

    const ifKeyExists = function (k, array) {
        if (array) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < array.length; i++) {
                // eslint-disable-next-line eqeqeq
                if (array[i] == k) {
                    return true;
                }
            }
        }
        return false;
    };

    const replaceAll = function (str, find, replace) {
        const strToReplace = str || '';
        return strToReplace.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    };

    const camalize = function camalize(str) {
        return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
            return chr.toUpperCase();
        });
    };

    const escapeRegExp = function (str) {
        // eslint-disable-next-line no-useless-escape
        return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
    };

    const prependSpace = function (str) {
        return str && str[0] !== ' ' ? ` ${str}` : str;
    };

    const wrapWithHtmlAttribute = function (str, attr) {
        return !str.includes(attr) ? `${attr}="${str}"` : str;
    };

    const getImageNaturalDimensions = function (imgLink, callback) {
        const newImg = new Image();

        newImg.onload = function () {
            callback({ width: newImg.width, height: newImg.height });
        };

        newImg.src = imgLink; // this must be done AFTER setting onload
    };

    const shuffleArray = function (array) {
        let currentIndex = array.length;
        let temporaryValue;
        let randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex !== 0) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            // eslint-disable-next-line no-param-reassign
            array[currentIndex] = array[randomIndex];
            // eslint-disable-next-line no-param-reassign
            array[randomIndex] = temporaryValue;
        }

        return array;
    };

    const getEnvPrefix = function (isProdPrefix) {
        const appEnv = AsgFW.getAppEnv();
        if (appEnv) {
            return appEnv === AsgFW.EnvVars.PROD ? '' : `${appEnv}.`;
        }

        // Fallback
        const url = window.location.host;
        const isProdPre = typeof isProdPrefix !== 'undefined' ? isProdPrefix : false;
        let envPrefix = isProdPre ? 'www.' : '';
        if (/(\b|[_-])dev([_-]|\b)/i.test(url)) {
            envPrefix = 'dev.';
        } else if (/(\b|[_-])qa([_-]|\b)/i.test(url)) {
            envPrefix = 'qa.';
        } else if (/(\b|[_-])uat([_-]|\b)/i.test(url)) {
            envPrefix = 'uat.';
        }
        return envPrefix;
    };

    /**
     * Return the environment name (DEV, QA, UAT, PROD).
     * @returns {string}
     */
    const getEnvName = () => {
        const appEnv = AsgFW.getAppEnv();
        if (appEnv) {
            return appEnv.toUpperCase();
        }

        // Fallback
        let env = AsgFW.getAsgServerPrefix();
        env = env ? env.slice(0, -1) : AsgFW.EnvVars.PROD;
        return env.toUpperCase();
    };

    /**
     * Detects if current site is opened on DEV env or in Debug mode (with '?debug' query parameter)
     * @returns {boolean}
     */
    const isDebug = function () {
        const isDev = getEnvPrefix() === 'dev.';
        const isDebugParam = new URLSearchParams(window.location.search).has('debug');

        // for testing bundles locally
        if (isDev && isDebugParam) {
            return false;
        }

        return isDev || isDebugParam;
    };

    /**
     * Accepts log messages from the code.
     * If site is in debug mode - show more information.
     * @return undefined
     * @param {string} msg
     * @param {any} debugData
     */
    const log = function (msg, debugData = null) {
        // Send data to the browser console.
        consoleLog(msg, debugData);
    };

    const error = function (msg, debugData = null) {
        // Send data to the browser console with red colors.
        consoleLog(msg, debugData, true);
    };

    /**
     * Displays log message to the browser console.
     * If site is in debug mode - show more information.
     * @return undefined
     * @param {string} msg
     * @param {any} debugData
     * @param isError
     */
    const consoleLog = function (msg, debugData = null, isError = false) {
        // Send a message to the console.
        let color = '#4cb848';
        if (isError) {
            color = '#ff0000';
        }
        console.log(`%cASG:%c ${msg}`, `background-color: ${color}; color: #fff`, `color: ${color}`);
        if (isDebug() && !!debugData) {
            // If dev or Debug mode - show more details to the console.
            console.log('%cASG debug data:', 'color: #ff6802', debugData);
        }
    };

    const appendCss = function (fileName) {
        const { head } = document;
        const link = document.createElement('link');

        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = fileName;

        head.appendChild(link);
    };

    const appendJS = function (fileName) {
        const newScript = document.createElement('script');
        newScript.src = fileName;
        newScript.defer = true;
        document.body.appendChild(newScript);
    };

    /**
     * Loads script on page.
     * Returns Promise that will be resolved when script is loaded
     * @param scriptUrl
     * @param isAsync - defines the async load type
     * @return {Promise<undefined>}
     */
    const appendJSAsync = function (scriptUrl, isAsync = false) {
        return new Promise((resolve, reject) => {
            const scriptElement = document.createElement('script');
            scriptElement.src = scriptUrl;
            if (isAsync) {
                scriptElement.async = true;
            } else {
                scriptElement.defer = true;
            }
            document.body.appendChild(scriptElement);

            scriptElement.onload = () => {
                resolve();
            };

            scriptElement.onerror = () => {
                reject();
            };
        });
    };

    const fixAnchorAnimate = function () {
        const animateOffset = function (hash) {
            if (hash.length > 1) {
                if (document.querySelector(`a[name*="${hash}"]`)) {
                    const offset = document.querySelector(`a[name*="${hash}"]`).offsetTop + 550;
                    window.scrollTo({
                        top: offset,
                        behavior: 'smooth',
                    });
                }
            }
        };

        document.querySelectorAll('a[href^="#"]:not([data-disable-scroll-animate])').forEach(function (link) {
            link.addEventListener('click', function (e) {
                e.preventDefault();
                animateOffset(this.hash.replace('#', ''));
            });
        });
    };

    const getParameters = function (url, toLowerCase) {
        // eslint-disable-next-line no-param-reassign
        toLowerCase = toLowerCase || true;
        const params = {};

        if (toLowerCase) {
            // eslint-disable-next-line no-param-reassign
            url = url.toLowerCase();
        }

        // eslint-disable-next-line no-param-reassign
        url = url.split('&');

        const { length } = url;

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < length; i++) {
            const prop = url[i].slice(0, url[i].search('='));
            params[prop] = url[i].slice(url[i].search('=')).replace('=', '');
        }

        return params;
    };

    const getNavUrl = function () {
        let result = window.location.search.replace('?', '');
        if (result.substr(-1) === '/') {
            result = result.slice(0, -1);
        }
        return result;
    };

    // Capitalize first letter of a string
    const capitalize = function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    /**
     * Replace empty value with replaced
     * @param value
     * @param replaced
     * @return {*}
     */
    const replaceEmpty = (value, replaced) => (!value ? replaced : value);

    const getBrowserName = function () {
        let result = null;
        const { userAgent } = window.navigator;
        const { vendor } = window.navigator;

        if (userAgent.match(/Android/i)) {
            if (
                /Chrome/.test(userAgent)
                && /Google Inc/.test(vendor)
                && !/UCBrowser/.test(userAgent)
                && !/OPR/.test(userAgent)
                && !/SamsungBrowser/.test(userAgent)
            ) {
                result = 'Chrome';
            } else if (/OPR/.test(userAgent)) {
                result = 'OPR';
            } else if (/UCBrowser/.test(userAgent)) {
                result = 'UC';
            } else if (/Firefox/.test(userAgent) || /FxiOS/.test(userAgent)) {
                result = 'Firefox';
            } else if (/SamsungBrowser/.test(userAgent) && /Google Inc/.test(vendor)) {
                result = 'SamsungBrowser';
            }
        } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
            if (!!userAgent.match(/WebKit/i) && !userAgent.match(/CriOS/i) && /FxiOS/.test(userAgent)) {
                result = 'FireFox';
            } else if (/CriOS/.test(userAgent)) {
                result = 'Chrome';
            } else if (!!userAgent.match(/WebKit/i) && !userAgent.match(/CriOS/i) && !/EdgiOS/.test(userAgent)) {
                result = 'Safari';
            } else if (/EdgiOS/.test(userAgent)) {
                result = 'Edge';
            }
        }
        return result;
    };
    /**
     * Internet explorer returns TRUE
     * good browser returns FALSE
     * @returns {boolean}
     */
    const isIE = function () {
        const ua = window.navigator.userAgent;
        return /MSIE|Trident/.test(ua);
    };

    /**
     * converts 2 letter country code into FreshChat locale
     * @param country
     * @returns string
     */
    const getFreshChatLocale = function (country) {
        const iso639 = {
            au: 'en',
            at: 'de',
            de: 'de',
            ru: 'ru',
            es: 'es',
            fr: 'fr',
            be: 'fr',
            dk: 'da',
            da: 'nl',
            it: 'it',
            nl: 'nl',
            fi: 'fi',
            uk: 'en',
            en: 'en',
            ca: 'en',
            za: 'en',
            pt: 'pt',
            br: 'pt-BR',
            no: 'nb',
            nz: 'nz',
        };

        return iso639[country] ? iso639[country] : 'en';
    };

    /**
     * converts html entity to ascii
     * @param {string} text
     * @returns {string}
     */
    function decodeHTMLEntities(text) {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    }

    /**
     * converts ascii to html entity
     * @param {string} text
     * @returns {string}
     */
    function encodeHTMLEntities(text) {
        const textArea = document.createElement('textarea');
        textArea.innerText = text;
        return textArea.innerHTML;
    }
    /**
     * formats a number by adding commas
     * @param {string} n
     * @returns {string}
     */
    function formatNumber(n) {
        return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    /**
     * formats an input element with commas and a currency sign.
     * @param {HTMLElement} input DOM element
     * @param {string} blur: 'remove' to remove decimal point, 'blur' to fix decimal point
     * @param {string} currencySign
     */
    function formatCurrency(input, blur, currencySign) {
        // appends $ to value, validates decimal side
        // and puts cursor back in right position.

        // get input value
        let inputValue = input.val();

        // don't validate empty input
        if (inputValue === '') { return; }

        // original length
        const originalLength = inputValue.length;

        // initial caret position
        let caretPositoin = input.prop('selectionStart');

        if (blur === 'remove') {
            inputValue = inputValue.replace('.', '');
        }

        // check for decimal
        if (inputValue.indexOf('.') >= 0) {
            // get position of first decimal
            // this prevents multiple decimals from
            // being entered
            const decimalPosition = inputValue.indexOf('.');

            // split number by decimal point
            let leftSide = inputValue.substring(0, decimalPosition);
            let rightSide = inputValue.substring(decimalPosition);

            // add commas to left side of number
            leftSide = formatNumber(leftSide);

            // validate right side
            rightSide = formatNumber(rightSide);

            // On blur make sure 2 numbers after decimal
            if (blur === 'blur') {
                rightSide += '00';
            }

            // Limit decimal to only 2 digits
            rightSide = rightSide.substring(0, 2);

            // join number by .
            inputValue = `${currencySign}${leftSide}.${rightSide}`;
        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            inputValue = formatNumber(inputValue);
            inputValue = currencySign + inputValue;

            // final formatting
            if (blur === 'blur') {
                inputValue += '.00';
            }
        }

        // send updated string to input
        input.val(inputValue);

        // put caret back in the right position
        const updatedLength = inputValue.length;
        caretPositoin = updatedLength - originalLength + caretPositoin;
        input[0].setSelectionRange(caretPositoin, caretPositoin);
    }

    /**
     * A replacement to $.Deferred()
     * @return {Promise<unknown>}
     */
    const deferred = function () {
        let res;
        let rej;

        const promise = new Promise((resolve, reject) => {
            res = resolve;
            rej = reject;
        });

        promise.resolve = res;
        promise.reject = rej;
        return promise;
    };

    /**
     *  Loads a script into the dom. A replacement to $.getScript()
     * @param {string} source
     * @param {function} callback
     * @param {object|null} extraAttrs
     */
    const getScript = function (source, callback, extraAttrs = null) {
        let script = document.createElement('script');
        const prior = document.getElementsByTagName('script')[0];
        script.defer = true;

        // eslint-disable-next-line no-multi-assign
        script.onload = script.onreadystatechange = function (_, isAbort) {
            if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
                // eslint-disable-next-line no-multi-assign
                script.onload = script.onreadystatechange = null;
                script = undefined;

                if (!isAbort && callback) {
                    setTimeout(callback, 0);
                }
            }
        };

        script.src = source;
        if (extraAttrs) {
            Object.entries(extraAttrs).forEach(([attr, val]) => {
                script[attr] = val;
            });
        }
        prior.parentNode.insertBefore(script, prior);
    };

    /**
     * Convert HTML string into DOM element
     * @param str
     * @return {HTMLElement}
     */
    const stringToHTML = function (str) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(str, 'text/html');
        return doc.body;
    };

    /**
     * Jquery parents() polyfill
     * @param elem
     * @param selector
     * @return {[]}
     */
    const getParents = function (elem, selector) {
        const elements = [];
        const ishaveselector = selector !== undefined;

        // eslint-disable-next-line no-cond-assign, no-param-reassign
        while ((elem = elem.parentElement) !== null) {
            if (elem.nodeType !== Node.ELEMENT_NODE) {
                continue;
            }

            if (!ishaveselector || elem.matches(selector)) {
                elements.push(elem);
            }
        }

        return elements;
    };

    /**
     * Add CSS to page header
     * @param rule - String
     */
    const addCssToPage = function (rule) {
        const styleEl = document.createElement('style');

        // Append <style> element to <head>
        document.head.appendChild(styleEl);
        const styleSheet = styleEl.sheet;
        styleSheet.insertRule(rule, styleSheet.cssRules.length);
    };

    const getLinkTargetAttr = function (targetCode) {
        let linkTarget = '';

        if (targetCode === LINK_OPEN_TYPES.NEW_TAB) {
            linkTarget = 'target="_blank"';
        } else if (targetCode === LINK_OPEN_TYPES.NEW_WINDOW) {
            linkTarget = 'onclick="Tools.openNewWindow(event, this.href);"';
        }

        return linkTarget;
    };

    const getLinkTargetAttrPopup = function (url, targetCode) {
        if (url === null) {
            return;
        }

        let linkTarget = '';

        if (targetCode === LINK_OPEN_TYPES.NEW_TAB) {
            linkTarget = `href="${url}" target="_blank"`;
        } else if (targetCode === LINK_OPEN_TYPES.NEW_WINDOW) {
            linkTarget = `href="${url}" onclick="Tools.openNewWindow(event, this.href);"`;
        } else if (targetCode === LINK_OPEN_TYPES.SAME_TAB) {
            // convert direct info page URL to aspx param
            const aspxLink = url.replace('/info/', '')
                .replace('/', '')
                .replace(/-/g, '')
                .concat('.aspx')
                .toLowerCase();

            linkTarget = `onclick="AsgApp.openInfoPopup('${aspxLink}', event);"`;
        }

        return linkTarget;
    };

    const openNewWindow = function (event, location) {
        event.preventDefault();
        event.stopImmediatePropagation();

        window.open(location, 'targetWindow', `width=${window.outerWidth},height=${window.outerHeight}`);
    };

    /**
     * Calculate the time between param timeStart and param timeEnd.
     * Return object with the current state of time.
     * @param timeStart date
     * @param timeEnd date
     * @returns object
     */
    const getTimeMeasure = function (timeStart, timeEnd) {
        const dateStart = new Date(timeStart);
        const dateEnd = new Date(timeEnd);

        let seconds = Math.floor((dateEnd - (dateStart)) / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        hours -= (days * 24);
        minutes = minutes - (days * 24 * 60) - (hours * 60);
        seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

        return {
            seconds,
            minutes,
            hours,
            days,
        };
    };

    const compareObjects = (firstObject, secondObject) => (
        JSON.stringify(firstObject) === JSON.stringify(secondObject)
    );

    const compareShallowObjects = (firstObject, secondObject) => (
        Object.entries(firstObject).toString() === Object.entries(secondObject).toString()
    );

    const debounce = function (func, delay) {
        let timer;
        return function () {
            const context = this;
            // eslint-disable-next-line prefer-rest-params
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };

    const throttle = (func, delay) => {
        let timeout = null;

        return function (...args) {
            if (timeout === null) {
                func.apply(this, args);

                timeout = setTimeout(() => {
                    timeout = null;
                }, delay);
            }
        };
    };

    /**
     * Creates custom performance mark to track important code events.
     * @param name The name of the mark.
     * @return void
     */
    const track = (name) => {
        window.performance.mark(name);
        setDatadogProp('action', name);
        // Fetch 1px image with custom name to track the metric in the network waterfall chart.
        fetch(`${AsgFW.getFrameworkUri()}data/general-data/track.png?n=${name}`)
            .catch(() => { /* ignore fetch errors. */ });
    };

    /**
     * Logs performance-specific metric to the console.
     * @param description Explains the meaning of the metric.
     * @param entryName The type or name of the metric.
     * @param prop The time-property of the metric to display.
     * @return void
     */
    const logPerformance = (description, entryName, prop) => {
        const entry = window.performance.getEntriesByType(entryName)[0]
            || window.performance.getEntriesByName(entryName)[0] || null;
        if (entry) {
            const time = (entry[prop] / 1000).toFixed(2);
            console.log(`%c${description}%c ${time}s`, 'color: #4cb848', 'color: #1a1aa6');
        }
    };

    /**
     * Generates console report on some performance metrics.
     * @return void
     */
    const getPerformanceReport = () => {
        console.log('%cPerformace report', 'color: #c80000');
        try {
            // Core level metrics.
            performance.measure('NGLoadDuration', 'NGLoad:start', 'NGReadyEvent');
            performance.measure('ASGReadyDuration', 'NGReadyEvent', 'ASGReadyEvent');
            console.group('Core events');
            logPerformance('First paint at: ', 'first-paint', 'startTime');
            logPerformance('First contentful paint at: ', 'first-contentful-paint', 'startTime');
            logPerformance('NG Loading started at: ', 'NGLoad:start', 'startTime');
            logPerformance('DOMContentLoaded Event at: ', 'navigation', 'domContentLoadedEventEnd');
            logPerformance('DOMComplete Event at: ', 'navigation', 'domComplete');
            logPerformance('Load Event at: ', 'navigation', 'loadEventEnd');
            logPerformance('NG is ready at: ', 'NGReadyEvent', 'startTime');
            logPerformance('ASG is ready at: ', 'ASGReadyEvent', 'startTime');
            logPerformance('Games list updated (GGL) at: ', 'gamesListUpdated', 'startTime');
            console.groupEnd();
            // ASG templates-specific metrics.
            console.group('Template-level events');
            logPerformance('GamesReady Event at: ', 'gamesReadyEvent', 'startTime'); // AsgApp level.
            logPerformance('Games grid is ready at: ', 'gamesGridRendered', 'startTime'); // AppUI (template) level.
            console.groupEnd();
            console.group('FW preparation durations');
            logPerformance('NG preparation duration: ', 'NGLoadDuration', 'duration');
            logPerformance('ASG preparation duration (after NG is ready): ', 'ASGReadyDuration', 'duration');
            console.groupEnd();
        } catch {
            // Ignore some absent template-level metrics.
        }
        console.group('Common page stats');
        console.log(performance.getEntriesByType('navigation')[0]); // Common page performance stats object.
        console.groupEnd();
    };

    const isDeviceEmulatorMode = () => (navigator.userAgent.includes('Mobile')
        && navigator.maxTouchPoints === 1);

    const typeOf = (obj) => Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

    /**
     * Rejects promise in X seconds.
     * Can be used with Promise.race() to do request/events timeouts
     */
    const timeoutAfter = (seconds, rejectOnTimeOut = true) => (new Promise((resolve, reject) => {
        setTimeout(() => {
            if (rejectOnTimeOut) {
                reject(new Error('request timed-out'));
            } else {
                resolve(true);
            }
        }, seconds * 1000);
    }));

    /**
     * Embeds a Datadog RUM (real user monitoring)
     */
    const embedDatadogRum = () => {
        const datadogRumEnabled = AsgFW.getRbcSettings('datadogRumEnabled');
        const config = AsgFW.getRbcSettings('datadogRumConfig');
        const isAsgHosted = AsgFW.isAsgHosted();

        if (!datadogRumEnabled || !config || !config.scriptUrl || !config.params) {
            return Promise.reject('Datadog RUM is not configured.');
        }

        // Datadog was embedded in the backend (for ASG Hosted brands).
        if (config.embedOnBackend && isAsgHosted) {
            return Promise.resolve();
        }

        // Update some parameter values.
        if (!config.params.service) {
            config.params.service = AsgFW.getBrandDomain();
        }

        if (!config.params.env) {
            config.params.env = getEnvName();
        }

        if (!config.params.version) {
            config.params.version = AsgFW.getVersion();
        }

        return appendJSAsync(config.scriptUrl, true).then(() => {
            window.DD_RUM.onReady(() => {
                window.DD_RUM.init(config.params);
                window.DD_RUM.startSessionReplayRecording();
                Tools.log('Datadog RUM is ready (JS).');
            });
        });
    };

    /**
     * Set Datadog property
     * @param type Property type (action, context or user)
     * @param name Property name
     * @param value Property value
     */
    const setDatadogProp = (type, name, value) => {
        if (AsgFW.getRbcSettings('datadogRumEnabled') && window.DD_RUM) {
            const methodsMap = {
                action: 'addAction',
                context: 'setGlobalContextProperty',
                user: 'setUserProperty',
            };

            window.DD_RUM.onReady(() => {
                window.DD_RUM[methodsMap[type]](name, value);
            });
        }
    };

    function uuidV4() {
        if (window.crypto?.randomUUID) {
            return crypto.randomUUID();
        }

        const uuid = new Array(36);
        for (let i = 0; i < 36; i++) {
            uuid[i] = Math.floor(Math.random() * 16);
        }
        uuid[14] = 4; // set bits 12-15 of time-high-and-version to 0100
        uuid[19] = uuid[19] &= ~(1 << 2); // set bit 6 of clock-seq-and-reserved to zero
        uuid[19] = uuid[19] |= (1 << 3); // set bit 7 of clock-seq-and-reserved to one
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        return uuid.map((x) => x.toString(16)).join('');
    }

    const generateReferenceHeaders = () => {
        return {
            'x-request-id': uuidV4(),
        };
    };

    // Public interface.
    return {
        addCssToPage,
        replaceAll,
        getImageNaturalDimensions,
        shuffleArray,
        getEnvPrefix,
        getEnvName,
        appendCss,
        appendJS,
        fixAnchorAnimate,
        getParameters,
        getNavUrl,
        capitalize,
        camalize,
        prependSpace,
        wrapWithHtmlAttribute,
        getBrowserName,
        isIE,
        ifKeyExists,
        getFreshChatLocale,
        formatCurrency,
        decodeHTMLEntities,
        encodeHTMLEntities,
        isDebug,
        log,
        error,
        consoleLog,
        deferred,
        getScript,
        stringToHTML,
        getParents,
        appendJSAsync,
        openNewWindow,
        getLinkTargetAttr,
        getLinkTargetAttrPopup,
        getTimeMeasure,
        compareObjects,
        compareShallowObjects,
        debounce,
        throttle,
        track,
        getPerformanceReport,
        replaceEmpty,
        isDeviceEmulatorMode,
        typeOf,
        timeoutAfter,
        embedDatadogRum,
        setDatadogProp,
        uuidV4,
        generateReferenceHeaders,
    };
})();

/**
 * URL Service
 * */
var UrlService = (function () {
    const updateQueryString = function (key, value, url) {
        if (!url && url !== '') {
            // eslint-disable-next-line no-param-reassign
            url = window.location.href;
        }
        const re = new RegExp(`([?&])${key}=.*?(&|#|$)(.*)`, 'gi');
        let hash;

        if (re.test(url)) {
            if (typeof value !== 'undefined' && value !== null) {
                return url.replace(re, `$1${key}=${value}$2$3`);
            }

            hash = url.split('#');
            // eslint-disable-next-line no-param-reassign
            url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
            if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
                // eslint-disable-next-line no-param-reassign
                url += `#${hash[1]}`;
            }
            return url;
        }

        if (typeof value !== 'undefined' && value !== null) {
            const separator = url.indexOf('?') !== -1 ? '&' : '?';
            hash = url.split('#');
            // eslint-disable-next-line no-param-reassign
            url = `${hash[0]}${separator}${key}=${value}`;

            if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
                // eslint-disable-next-line no-param-reassign
                url += `#${hash[1]}`;
            }

            return url;
        }

        return url;
    };

    // gets the params / specified param in the query
    const getParams = function (param) {
        const paramsStrings = location.search.substr(1).split('&');
        const params = [];
        paramsStrings.forEach(function (value) {
            params.push({
                key: value.substr(0, value.indexOf('=')),
                value: value.substr(value.indexOf('=') + 1),
            });
        });

        let res;
        if (param) {
            params.forEach(function (val) {
                if (val.key === param) {
                    res = val;
                }
            });
        } else {
            res = params;
        }

        return res;
    };

    /**
     * Deletes URL search (query) parameter from the URL.
     * @param parameter The parameter to be removed from the URL
     * @param url URL to operate on or the current location href.
     * @returns {string} The URL string
     */
    const deleteParameter = (parameter, url = window.location.href) => {
        const urlObj = new URL(url, window.location.origin);
        urlObj.searchParams.delete(parameter);
        return urlObj.toString();
    };

    /**
     * Compares if the target's host matches the current URL's host.
     * @param target
     * @param ignoreBrandSubdomain
     * @returns {boolean}
     */
    const isSameHost = (target, ignoreBrandSubdomain = true) => {
        const { hostname: currentHost } = window.location;
        const { hostname: targetHost } = new URL(target, window.location.origin);
        return ignoreBrandSubdomain
            ? filterBrandSubdomain(currentHost) === filterBrandSubdomain(targetHost)
            : currentHost === targetHost;
    };

    /**
     * @param hostName
     * @returns {string|*}
     */
    const filterBrandSubdomain = (hostName) => {
        const hostNameBrandWithoutSubdomain = AsgFW.getBrandDomainWithEnvPrefix();
        return hostName.indexOf(hostNameBrandWithoutSubdomain) !== -1
            ? hostNameBrandWithoutSubdomain
            : hostName;
    };

    /**
     * Compares if the target's host and path matches the current URL's host and path.
     * @param target
     * @returns {boolean}
     */
    const isSameHostPath = (target) => {
        const { pathname: curPath } = window.location;
        const { pathname: tPath } = new URL(target, window.location.origin);
        return isSameHost(target) && curPath === tPath;
    };

    /**
     * Joins target's URL search parameters with the source's (base's) URL search parameters.
     * @param targetUrl The target/new URL
     * @param sourceUrl The source URL, which search parameters should extend the target's ones.
     * @returns {*|string} Target URL with the updated search parameters.
     */
    const joinUrlsSearchParams = (targetUrl, sourceUrl) => {
        const currentBase = window.location.origin;

        try {
            const targetUrlObj = new URL(targetUrl, currentBase);
            const sourceUrlObj = new URL(sourceUrl, currentBase);
            const targetUrlSearchParams = new URLSearchParams(targetUrlObj.search.toLowerCase());
            const sourceUrlSearchParams = new URLSearchParams(sourceUrlObj.search.toLowerCase());

            for (const [key, val] of sourceUrlSearchParams.entries()) {
                if (!targetUrlSearchParams.has(key)) {
                    targetUrlSearchParams.append(key, val);
                }
            }
            targetUrlObj.search = `?${targetUrlSearchParams.toString()}`;

            return targetUrlObj.toString();
        } catch (e) {
            console.error('joinUrlsSearchParams error', e);
            return targetUrl;
        }
    };

    /**
     * Joins target's URL search parameters with the current page URL search parameters,
     * filtering by the allowed list parameters.
     * @param targetUrl
     * @returns {*|string}
     */
    const joinCurrentUrlSearchParams = (targetUrl) => (
        joinUrlsSearchParams(targetUrl, window.location.href));

    const getPathName = () => window.location.pathname;

    const trimSlashes = string => string
        .replace(/^\/?/, '') // trim slash at the begining if exists
        .replace(/\/?$/, ''); // trim slash at the end if exists

    return {
        updateQueryString,
        getParams,
        deleteParameter,
        isSameHost,
        isSameHostPath,
        joinUrlsSearchParams,
        joinCurrentUrlSearchParams,
        getPathName,
        trimSlashes,
    };
})();

/**
 * Cookies
 * */
// eslint-disable-next-line no-unused-vars
var Cookie = (function () {
    const create = function (name, value, expires, path, domain) {
        let cookie = `${name}=${value};`;

        if (expires) {
            // If it's a date
            if (expires instanceof Date) {
                // If it isn't a valid date
                if (isNaN(expires.getTime())) {
                    // eslint-disable-next-line no-param-reassign
                    expires = new Date();
                }
            } else if (expires instanceof Object) {
                /**
                 * expires by object must include a "minutes" kye.
                 * value > should be entered in minutes
                 * for example : {"minutes":12}
                 * */
                // eslint-disable-next-line no-param-reassign
                expires = new Date(
                    new Date().getTime() + parseInt(expires.minutes, 10) * 1000 * 60,
                );
            } else {
                // eslint-disable-next-line no-param-reassign
                expires = new Date(
                    new Date().getTime() + parseInt(expires, 10) * 1000 * 60 * 60 * 24,
                );
            }
            cookie += `expires=${expires.toGMTString()};`;
        }

        if (path) {
            cookie += `path=${path};`;
        }

        // eslint-disable-next-line no-param-reassign
        domain = domain || getDomainCookie();
        cookie += `domain=${domain};`;
        cookie += 'secure;';

        document.cookie = cookie;
    };

    const remove = function (name, path, domain) {
        // If the cookie exists
        // eslint-disable-next-line no-param-reassign
        domain = domain || getDomainCookie();

        if (read(name)) {
            create(name, '', -1, path, domain);
        }
    };

    const read = function (name) {
        const nameEQ = `${name}=`;
        const ca = document.cookie.split(';');
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }

            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }

        return null;
    };

    const getDomainCookie = function () {
        return `.${AsgFW.getBrandDomain()}`;
    };

    return {
        create,
        read,
        remove,
    };
})();

/**
 * Third party Services
 * TODO: Toremve after the announcement.
 * @deprecated
 * */
// eslint-disable-next-line no-unused-vars
var ExternalApiService = (function () {
    /**
     * deprecated
     * @returns {Promise<string>}
     */
    const getTimeGMT = function () {
        return fetch('https://fnc.aspireglobal.com/asg-framework/helpers/WorldTimeAPI/world_time_api.php', {
            method: 'GET',
            cache: 'no-cache',
        }).then(response => response.text());
    };

    // Public interface.
    return {
        getTimeGMT,
    };
})();

/**
 * SolarAPI
 * Helps to communicate with SOLAR API.
 * */
const SolarAPI = (function () {
    let globalHeaders;

    // Init Solar API.
    const init = function (config) {
        globalHeaders = {
            Authorization: `Bearer ${config.solarKey}`,
        };
    };

    // Get Solar API URL.
    const getSolarAPIUrl = function () {
        const solarHost = AsgFW.getSolarHost();
        if (solarHost) {
            return `https://${solarHost}`;
        }

        // Fallback
        let env;
        const asgSrvEnvOverride = AsgFW.getAsgSrvEnvOverride();
        // Handle 'env' query param.
        const validEnvs = ['prod', 'dev', 'qa', 'uat'];
        let envQuery = window.UrlService.getParams('env');
        if (!envQuery && asgSrvEnvOverride) {
            envQuery = { key: 'env', value: asgSrvEnvOverride };
        }
        envQuery = envQuery && validEnvs.indexOf(envQuery.value) !== -1 ? `-${envQuery.value}` : undefined; // Set the value of env query param + dash or return undefined back.
        envQuery = envQuery && (envQuery === '-prod') ? '' : envQuery; // Prod env must be empty.

        // Handle env from subdomain detection.
        let envPrefix = Tools.getEnvPrefix();
        envPrefix = envPrefix.slice(0, -1); // Cut off last symbol (.)
        envPrefix = envPrefix === '' ? '' : `-${envPrefix}`; // Add dash to all envs, except Prod.

        if (envQuery !== undefined) {
            // Use 'env' query param, if it was provided
            env = envQuery;
        } else if (!asgSrvEnvOverride && envPrefix !== '') {
            // API brands should be pointed to prod by default if no override env specified
            // even if its domain structure same as ASG
            // ASG brands will always have asgSrvEnvOverride
            env = '';
        } else {
            // in other cases - use detected by domain env
            env = envPrefix;
        }

        return `https://api${env}.aspireglobal.com`;
    };

    /**
     * Executes GET request for specified URN (endpoint).
     * @param urn
     * @param queryParams
     * @param successCallback
     * @param errorCallback
     */
    const get = function (urn, queryParams, successCallback, errorCallback) {
        const localUrn = prepareURN(urn);
        const localQueryParams = (typeof queryParams === 'object') ? (new URLSearchParams(Object.entries(queryParams))).toString() : '';
        const localSuccessCallback = (successCallback instanceof Function)
            ? successCallback : successHandler;
        const localErrorCallback = (errorCallback instanceof Function) ? errorCallback : solarError;

        return fetch(`${SolarAPI.getSolarAPIUrl() + localUrn}?${localQueryParams}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                ...globalHeaders,
                'Content-Type': 'application/json; charset=utf-8"',
                ...Tools.generateReferenceHeaders(),
            },
        })
            .then(response => {
                if (!response.ok) {
                    Tools.error('SolarAPI: HTTP error', {
                        status: response.status,
                        statusText: response.statusText,
                    });
                }
                return response.json();
            })
            .then(localSuccessCallback)
            .catch(localErrorCallback);
    };

    /**
     * Executes POST request for specified URN (endpoint).
     * @param urn
     * @param params
     * @param successCallback
     * @param errorCallback
     */
    const post = function (urn, params, successCallback, errorCallback) {
        const localUrn = prepareURN(urn);
        const localParams = (typeof params === 'object') ? JSON.stringify(params) : '{}';
        const localSuccessCallback = (successCallback instanceof Function)
            ? successCallback : successHandler;
        const localErrorCallback = (errorCallback instanceof Function) ? errorCallback : solarError;

        return fetch(SolarAPI.getSolarAPIUrl() + localUrn, {
            method: 'POST',
            mode: 'cors',
            headers: {
                ...globalHeaders,
                'Content-Type': 'application/json; charset=utf-8',
                ...Tools.generateReferenceHeaders(),
            },
            body: localParams,
        })
            .then(response => response.json())
            .then(localSuccessCallback)
            .catch(localErrorCallback);
    };

    /**
     * Default error handler.
     * @param e
     */
    const solarError = function (e) {
        console.error('SolarAPI: AJAX error occurred', e);
        throw e;
    };

    /**
     * Default success handler
     * @param data
     */
    const successHandler = function (data) {
        if (data.error) throw data;
        console.log('SolarAPI: AJAX request was completed successfully', data);
        return data;
    };

    /**
     * Prepares SOLAR specific URNs.
     * @param urn
     * @returns {*}
     */
    const prepareURN = function (urn) {
        return urn.replace('{casinoId}', AsgFW.getBrandID());
    };

    // Expose public functions.
    return {
        init,
        getSolarAPIUrl,
        get,
        post,
        prepareURN,
    };
})();

/**
 * SolarGatewayAPI
 * Helps to communicate with SOLAR Gateway API.
 * */
// eslint-disable-next-line no-unused-vars
const SolarGatewayAPI = (function () {
    /**
     * Executes GET request for specified URN (endpoint).
     * @param urn
     * @param queryParams
     * @param successCallback
     * @param errorCallback
     */
    const get = function (urn, queryParams, successCallback, errorCallback) {
        const localUrn = SolarAPI.prepareURN(urn);
        const localQueryParams = prepareQueryParams(queryParams).toString();
        const localSuccessCallback = (successCallback instanceof Function)
            ? successCallback : successHandler;
        const localErrorCallback = (errorCallback instanceof Function) ? errorCallback : solarError;

        return fetch(`${getSolarGatewayApiUrl() + localUrn}?${localQueryParams}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json; charset=utf-8"',
                Accept: 'application/json',
                ...Tools.generateReferenceHeaders(),
            },
        })
            .then(response => response.json())
            .then(localSuccessCallback)
            .catch(localErrorCallback);
    };

    /**
     * @param queryParams
     * @returns {URLSearchParams}
     */
    const prepareQueryParams = (queryParams) => {
        const params = new URLSearchParams();
        if (typeof queryParams === 'object') {
            Object.entries(queryParams).forEach(([key, value]) => {
                if (typeof value === 'object') {
                    Object.entries(value).forEach(([subKey, subValue]) => {
                        // make param[1]=123&param[2]=456
                        params.append(`${key}[${subKey}]`, subValue);
                    });
                } else {
                    params.append(key, value);
                }
            });
        }
        return params;
    };

    /**
     * Executes POST request for specified URN (endpoint).
     * @param urn
     * @param params
     * @param successCallback
     * @param errorCallback
     */
    const post = function (urn, params, successCallback, errorCallback) {
        const localUrn = SolarAPI.prepareURN(urn);
        const localParams = (typeof params === 'object') ? JSON.stringify(params) : '{}';
        const localSuccessCallback = (successCallback instanceof Function)
            ? successCallback : successHandler;
        const localErrorCallback = (errorCallback instanceof Function) ? errorCallback : solarError;

        return fetch(getSolarGatewayApiUrl() + localUrn, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                ...Tools.generateReferenceHeaders(),
            },
            body: localParams,
        })
            .then(response => response.json())
            .then(localSuccessCallback)
            .catch(localErrorCallback);
    };

    const getSolarGatewayApiUrl = function () {
        const gatewayHost = AsgFW.getGatewayHost();
        if (gatewayHost) {
            return `https://${gatewayHost}`;
        }

        // Fallback
        return SolarAPI.getSolarAPIUrl().replace('api', 'gateway');
    };

    const successHandler = function (data) {
        if (data.error) throw data;
        console.log('SolarGatewayAPI: AJAX request was completed successfully', data);
        return data;
    };

    const solarError = function (e) {
        console.error('SolarGatewayAPI: AJAX error occurred', e);
        throw e;
    };

    // Expose public functions.
    return {
        get,
        post,
        getSolarGatewayApiUrl,
    };
})();

/**
 * PlatformAPI
 * Helps to communicate with Platform API.
 * */
const PlatformAPI = (function () {
    let globalHeaders;

    // Init Platform API.
    const init = function () {
        globalHeaders = {};
    };

    // Get Platform API URL.
    const getPlatformAPIUrl = function () {
        // Return URL from NeoCMS if integration enable and URL exists.
        if (AsgFW.getRbcSettings('neoCmsIntegration')) {
            const pamUrl = AsgFW.getPamUrls(AsgFW.PamInstanceUrlTypesEnum.PAM_ASGWEBAPI);
            if (pamUrl) {
                return UrlService.trimSlashes(pamUrl);
            }
        }

        const pamAsgWebApiHost = AsgFW.getPamAsgWebApiHost();
        if (pamAsgWebApiHost) {
            return `https://${pamAsgWebApiHost}`;
        }

        // Fallback
        // Handle 'env' query param.
        const validEnvs = ['prod', 'dev', 'qa', 'uat'];
        let envQuery = UrlService.getParams('env');

        // Set the value of env query param or return undefined back.
        envQuery = envQuery && validEnvs.indexOf(envQuery.value) !== -1 ? `${envQuery.value}.` : undefined;
        // Dev env must point to QA.
        envQuery = envQuery && envQuery === 'dev.' ? 'qa.' : envQuery;
        // UAT env must point to QA.
        envQuery = envQuery && envQuery === 'uat.' ? 'qa.' : envQuery;
        // Prod env must be empty.
        envQuery = envQuery && envQuery === 'prod.' ? '' : envQuery;

        // Handle env from subdomain detection.
        let envPrefix = Tools.getEnvPrefix();
        // Dev env must point to QA.
        envPrefix = envPrefix && envPrefix === 'dev.' ? 'qa.' : envPrefix;
        // UAT env must point to QA.
        envPrefix = envPrefix && envPrefix === 'uat.' ? 'qa.' : envPrefix;

        // Use 'env' query param, if it was provided, or use detected env instead.
        const env = typeof envQuery !== 'undefined' ? envQuery : envPrefix;

        return `https://asgwebapi.${env}gameserver1-mt.com`;
    };

    /**
     * Executes GET request for specified URN (endpoint).
     * @param urn
     * @param queryParams
     * @param successCallback
     * @param errorCallback
     */
    const get = function (urn, queryParams, successCallback, errorCallback) {
        // eslint-disable-next-line no-param-reassign
        urn = prepareURN(urn);
        // eslint-disable-next-line no-param-reassign
        queryParams = (typeof queryParams === 'object') ? (new URLSearchParams(Object.entries(queryParams))).toString() : '';
        // eslint-disable-next-line no-param-reassign
        successCallback = (successCallback instanceof Function) ? successCallback : successHandler;
        // eslint-disable-next-line no-param-reassign
        errorCallback = (errorCallback instanceof Function) ? errorCallback : platformError;

        return fetch(`${PlatformAPI.getPlatformAPIUrl()}${urn}?${queryParams}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                ...globalHeaders,
                'Content-Type': 'application/json; charset=utf-8',
                ...Tools.generateReferenceHeaders(),
            },
        })
            .then(response => response.json())
            .then(successCallback)
            .catch(errorCallback);
    };

    /**
     * Executes POST request for specified URN (endpoint).
     * @param urn
     * @param params
     * @param successCallback
     * @param errorCallback
     */
    const post = function (urn, params, successCallback, errorCallback) {
        // eslint-disable-next-line no-param-reassign
        urn = prepareURN(urn);
        // eslint-disable-next-line no-param-reassign
        params = (typeof params === 'object') ? JSON.stringify(params) : '{}';
        // eslint-disable-next-line no-param-reassign
        successCallback = (successCallback instanceof Function) ? successCallback : successHandler;
        // eslint-disable-next-line no-param-reassign
        errorCallback = (errorCallback instanceof Function) ? errorCallback : platformError;

        return fetch(PlatformAPI.getPlatformAPIUrl() + urn, {
            method: 'POST',
            mode: 'cors',
            headers: {
                ...globalHeaders,
                'Content-Type': 'application/json; charset=UTF-8',
                ...Tools.generateReferenceHeaders(),
            },
            body: params,
        })
            .then(response => response.json())
            .then(successCallback)
            .catch(errorCallback);
    };

    /**
     * Default error handler.
     * @param XHR
     */
    const platformError = function (XHR) {
        console.error('PlatformAPI: AJAX error occurred', XHR);
    };

    /**
     * Default success handler
     * @param data
     */
    const successHandler = function (data) {
        console.log('PlatformAPI: AJAX request was completed successfully', data);
    };

    /**
     * Prepares Platform API specific URNs.
     * @param urn
     * @returns {*}
     */
    const prepareURN = function (urn) {
        return urn.replace('{casinoId}', AsgFW.getBrandID());
    };

    // Expose public functions.
    return {
        init,
        getPlatformAPIUrl,
        get,
        post,
    };
})();

/*
 * Dictionary
 */
// eslint-disable-next-line no-unused-vars
const Dictionary = (function () {
    const init = function () {
        TranslationsAPI.init()
            .then(emitDictionaryReadyEvent);
    };
    const emitDictionaryReadyEvent = function () {
        document.dispatchEvent(new Event('dictionaryReady'));
    };

    const translate = function (key) {
        return TranslationsAPI.translate(key);
    };

    const translateString = function (str) {
        return TranslationsAPI.translateString(str);
    };

    /*
     * Public interface
     */
    return {
        init,
        translate,
        translateString,
    };
})();

/**
 * TranslationsAPI
 * An integration with Solar Translations API.
 */
const TranslationsAPI = (() => {
    const apiURN = '/api/v1/brands/{casinoId}/translations';
    let curLanguage;
    let curCountry;
    let curRegulation;
    let translations = {};
    let translationsReady;
    let translationsFailed;

    /**
     * Init Translations API
     * @param language
     * @param regulation
     * @param country
     */
    const init = (language, regulation, country) => new Promise((resolve, reject) => {
        translationsReady = resolve;
        translationsFailed = reject;
        const rbc = AsgFW.getRBC();
        curLanguage = language || AsgFW.getLanguage();
        curCountry = country || rbc.country;
        curRegulation = regulation || rbc.regulation;

        const translationsApiStorage = getTranslationsAPISessionStorage();
        if (!translationsApiStorage) {
            const queryParams = {
                language: curLanguage,
                regulation: curRegulation,
                country: curCountry,
            };
            // Execute request to SOLAR API.
            SolarAPI.get(apiURN, queryParams, solarSuccess);
        } else {
            translations = translationsApiStorage;
            translationsReady();
        }
    });

    const getTranslationsAPICacheKey = function () {
        return `TranslationsAPI_l:${curLanguage}_c:${curCountry}_r:${curRegulation}`;
    };

    /**
     * Get TranslationsAPI Session storage data.
     * @param newValue
     * @returns {any}
     */
    const getTranslationsAPISessionStorage = function () {
        const cacheKey = getTranslationsAPICacheKey();
        const value = sessionStorage.getItem(cacheKey);
        return value ? JSON.parse(value) : value;
    };

    /**
     * Set TranslationsAPI Session storage data.
     * @param newValue
     * @returns void
     */
    const setTranslationsAPISessionStorage = function (newValue) {
        const cacheKey = getTranslationsAPICacheKey();
        if (newValue) {
            sessionStorage.setItem(cacheKey, JSON.stringify(newValue));
        }
    };

    /**
     * Translations specific success request handler - fills the local translations with data.
     * @param data
     */
    const solarSuccess = (data) => {
        if ('data' in data && data.data[curLanguage.toLowerCase()]) {
            translations = data.data[curLanguage.toLowerCase()].translations;
            setTranslationsAPISessionStorage(translations);
            translationsReady();
        } else if ('error' in data) {
            console.error('SOLAR API response error: ', data.error);
            translationsFailed(data.error);
        }
    };

    /**
     * Translates keyword to its translation.
     * Basic public function.
     * @param string key
     * @returns string
     */
    const translate = (key) => {
        if (translations[key]) {
            return translations[key];
        }
        return key;
    };

    /**
     * Translates string with embedded dictionary | translations keys like:
     * "Text or code and [dictionary|join]".
     * @returns string
     * @param str
     */
    const translateString = (str) => {
        const regExp = /\[(dictionary|translations)([^\]]*)\]/;
        let text = str;
        let matches = text.match(regExp);
        while (matches) {
            const dictionaryKey = matches[2].substring(1);
            text = text.replace(matches[0], translate(dictionaryKey));
            matches = text.match(regExp);
        }
        return text;
    };

    /**
     * Get all translations.
     * @returns {{}}
     */
    const getTranslations = () => translations;

    /**
     * TranslationsAPI public interface
     */
    return {
        init,
        translate,
        translateString,
        getTranslations,
    };
})();

/**
 * LocalstorageTTLService
 */
// eslint-disable-next-line no-unused-vars
const LocalstorageTTLService = (() => {
    /**
     * @param key
     * @param value
     * @param ttl in milliseconds
     */
    const set = (key, value, ttl) => {
        const now = new Date();
        const item = {
            value,
            expire: now.getTime() + ttl,
        };

        localStorage.setItem(key, JSON.stringify(item));
    };

    /**
     * @param key
     * @returns {null|*}
     */
    const get = (key) => {
        const itemStr = localStorage.getItem(key);

        if (!itemStr) {
            return null;
        }
        const item = JSON.parse(itemStr);
        const now = new Date();
        // compare the expiry time
        if (now.getTime() > item.expire) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key);
            return null;
        }
        return item.value;
    };

    /**
     * LocalstorageTTLService public interface
     */
    return {
        get,
        set,
    };
})();

/**
 * Javascript extensions
 */

/**
 * Gets an object and a callback function, and returns a filtered object. Just like Array.filter
 * @param obj
 * @param predicate callback function
 */
Object.filter = function (obj, predicate) {
    const result = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key) && !predicate(obj[key])) {
            result[key] = obj[key];
        }
    }
    return result;
};

/*** EXPORTS FROM exports-loader ***/
export {
  Tools,
  UrlService,
  Cookie,
  ExternalApiService,
  PlatformAPI,
  SolarAPI,
  Dictionary,
  TranslationsAPI,
  SolarGatewayAPI,
  LocalstorageTTLService
};
